import './App.css';
import React,{useEffect , useState , } from 'react';
import LandingPage from './Component/LandingPage';

function App() {

  return (
  <LandingPage />  

  );
}

export default App;
