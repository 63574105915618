import React, { Component, useEffect, useState } from "react";
// import './Loader.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { saveInquiryAPI, dispatchLoadingStatus } from '../API/Action/globalAction';
import Loader from './Loader';
import swal from "sweetalert";
import google from "../Assets/Images/google-logo.png"
import apple from "../Assets/Images/apple-logo.png";

function LandingPage() {

   const dispatch = useDispatch({});
   // let history = useNavigate();


   const saveInquiryReg = useSelector(state => state.globalReducer.saveInquiryReg);


   const [days, setDays] = useState(0);
   const [hours, setHours] = useState(0);
   const [minutes, setMinutes] = useState(0);
   const [seconds, setSeconds] = useState(0);

   useEffect(() => {
      const day = 1000 * 60 * 60 * 24;
      const hour = 1000 * 60 * 60;
      const minute = 1000 * 60;
      const second = 1000;
  
      let countdownInterval = setInterval(() => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0");
        let yyyy = today.getFullYear();
        let nextYear = yyyy + 1;
        let dayMonth = "11/13/";
        let birthday = dayMonth + yyyy;
  
        today = mm + "/" + dd + "/" + yyyy;
  
        if (today > birthday) {
          birthday = dayMonth + nextYear;
        }
  
        const countDown = new Date(birthday).getTime();
        const now = new Date().getTime();
        const distance = countDown - now;
  
        setDays(Math.floor(distance / day));
        setHours(Math.floor((distance % day) / hour));
        setMinutes(Math.floor((distance % hour) / minute));
        setSeconds(Math.floor((distance % minute) / second));
  
        if (distance < 0) {
          clearInterval(countdownInterval);
        }
      }, 0);
  
      return () => clearInterval(countdownInterval);
   }, []);
   

   const [email, setEmail] = useState();
   const [emailError, setEmailError] = useState("");


   function isValidEmail(email) {
      return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
   }

   useEffect(() => {

      if (saveInquiryReg) {
         if (saveInquiryReg.errorcode == 0) {
            swal({
               text: saveInquiryReg.message,
               icon: "success",
               dangerMode: true,
             })

             setEmail("")
         } else {
            swal({
               text: saveInquiryReg.message,
               icon: "warning",
               dangerMode: true,
             })
  }
      }
  
    }, [saveInquiryReg])


   const handleEmail = (emailval) => {

      // const result = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/;
      // if (e.target.value === "" || result.test(e.target.value)) {
      //    setEmail(e.target.value);
      // }
      setEmail(emailval)


      if (emailval) {
        if (!isValidEmail(emailval)) {
          setEmailError("true")
        }
        else {
          setEmailError("")
        }
      }
      else {
        setEmailError("true");
      }
   }

   
   const Login = (e) => {
      e.preventDefault();
  
      if (email) {
        if (!isValidEmail(email)) {
           setEmailError("true")
           swal({
              text: "Please enter your valid email",
              icon: "warning",
              dangerMode: true,
            })
            //  setEmailError(<p style={{color : '#fff'}}>Email not valid</p>)
        }
        else {
          setEmailError("")
  
        }
      }
      else {
         setEmailError("true")
         swal({
            text: "Please enter your email",
            icon: "warning",
            dangerMode: true,
         })
      //   setEmailError(<p className='newwCard error-msg'>Email is required</p>);
      }
  
  
      // email && emailError == ''
  
      if (email && emailError == "") {
        let formData = new FormData();
        formData.append('email', email);
        dispatch(dispatchLoadingStatus(true))
        dispatch(saveInquiryAPI(formData))
  
      }
      else {

      }
    }

    const handleGoogle = () => {
      window.open('https://play.google.com/store/apps/details?id=co.techieworld.ourtravelapp', '_blank');
    }

    const handleApple = () => {
      window.open('https://apps.apple.com/us/app/our-travel-app/id6463644146?uo=2', '_blank');
    }

   return (
<>
<Loader />
      <div className="comin-soon-sec w-100 pt-3">
         {/* <section className="">
            <div className="container-fluid fluid-cont">
               <div className="row">
                  <div className="col-md-6">
                     
                  </div>
                  <div className="col-md-6">
                     <div className="main-timer">
                        <div id="countdown" className="float-end count-div">
                           <h4 id="headline" className="lunching-heading text-center">Launching in</h4>
                           <ul className="p-0 main-text-name">
                              <li><span id="days">{days}</span><p className="mt-2">days</p></li>
                              <li><span id="hours">{hours}</span><p className="mt-2">hours</p></li>
                              <li><span id="minutes">{minutes}</span><p className="mt-2">minutes</p></li>
                              <li><span id="seconds">{seconds}</span><p className="mt-2">seconds</p></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}

         <section className="text-img-sec">
            <div className="container-fluid fluid-cont">
               <div className="row align-items-center">
                  <div className="col-md-6 mt-lg-2 order-md-1 ipad-ccs">
                  <div className="main-logo">
                        <img src="images/logo22.svg" className="mb-5" width="120" />
                     </div>
                     <div className="text-contain">
                        <h3 className="text-white mb-3 font-size-20">We’re in this j(our)ney together.</h3>
                        <p className="invite-line mb-3">Invite. Plan. Collaborate. Travel. </p>
                        <div className="line"></div>
                        <p className="text-white mb-0 con-text-p">We've officially launched! <b>Download Now.</b> 
                        </p><p className="text-white mb-5 con-text-p">Enter your email below to get the latest updates!</p>
                           <div className="email-input">
                          
                           <input type="text" name="" maxLength={50} placeholder="Enter email address" className="email-box mb-5" value={email}onChange={(e) => handleEmail(e.target.value.trim())} />
                              <a href=""><img src="images/send-arrow.svg" width="50px" className="send-img" onClick={Login} /> </a>
                        </div>
                        {/* {emailError} */}
                     </div>
                  </div>
                  <div className="col-md-6 order-md-2 ipad-ccs">
                     <div className="phone-mockup">
                        <img src="images/phone-mockup.png" width="370" className="float-end"/>
                     </div>
                     <div className="d-flex bottom-two-btn-main">
                     <a className="navbar-brand mr-1" onClick={() => handleApple()} style={{ cursor: "pointer" }} title="Download">
                            <img src={apple} className="apple-logo-img m-auto d-block"/>
                          </a>
                     <a className="navbar-brand" onClick={() => handleGoogle()} style={{ cursor: "pointer" }} title="Download">
                            <img src={google} className="google-logo-img m-auto d-block"/>
                          </a>
                          
                     </div>
                  </div>
               </div>
               <div className="footer">
                  <ul className="d-flex list-unstyled pb-3">
                     <li><a href="https://www.techieworld.co/privacy-policy"  target="_blank" className="text-decoration-none text-white me-3 pe-3">App Privacy Policy</a></li>
                     {/* <li><a href="" className="text-decoration-none text-white">Contact Us</a></li> */}
                  </ul>
               </div>
            </div>
         </section></div>

         </>
   );
}


export default LandingPage;