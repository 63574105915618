// QA API
// export const baseURL = "https://stagingtravelapp.nichetechqa.com/api/";
// export const baseURL = process.env.REACT_APP_API_URL;

// Server API
export const baseURL = "https://api.ourtravelapp.com/api/";


export const isMainProject = true;

export const saveInquiry_URL = "front/saveInquiry";
export const saveInquiryReg = "saveInquiryReg";

export const IS_LOADING = "IS_LOADING";


