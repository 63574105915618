import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import Store from '../src/API/Store/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
  <App />
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();












{/* <div className="col-xxl-8 col-lg-8">
                <div className="card ">
                  <div className="card-header py-20  px-sm-25 px-3 ">
                    <h6>Polls</h6>
                  </div>

                  <div className="card-body">
                    <div className="mb-30">
                      <div className="atbd-collapse">

                      {state
                      ? state.polls.map((i,index) => ( 
                        <div className="atbd-collapse-item" key={index}>
                          <div
                            className={getClassNames(index)}
                            
                            >
                            <a
                              onClick={() => handleClick(index)}
                              className="item-link"
                              data-toggle="collapse"
                              data-target={`#collapse-body-${index + 1}`}
                              aria-expanded="true"
                              aria-controls={`collapse-body-${index + 1}`}

                              
                            >
                              <i className="la la-angle-right"></i>

                              <h3>{i.categoryName}</h3>
                            </a>
                          </div>
                          <div
                            id={`collapse-body-${index + 1}`}
                            className={index == 0 ?  `collapse atbd-collapse-item__body show` : `collapse atbd-collapse-item__body`} 
                          >
                            <div className="collapse-body-text">

                              <div className="row">
                              {i.answer_options ? i.answer_options.map((u) => (
                                <div className="col">
                                  <h5 className="fs-14 fw-600 color-dark mb-0">
                                    {u.option}
                                  </h5>
                                  <div className="user-group-progress-bar">
                                    <div className="progress-wrap d-flex align-items-center mb-0">
                                      <div className="progress">
                                        <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{ width: u.votes / i.responses * 100 + "%" }}
                                          aria-valuenow="50"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>

                                      <span className="progress-percentage">
                                      {i.responses == 0
                                        ? 0
                                        : (u.votes / i.responses) * 100}%
                                      </span>
                                    </div>

                                    <p className="color-light fs-12 mb-20">
                                      {u.votes } /  {i.responses} votes
                                    </p>
                                  </div>
                                  </div>
                                  )) : ""}
                                </div>
                            </div>
                          </div>
                        </div>
                        ))
                          : ""}
                        {state ? (
                          state.polls.length > 0 ? null : (
                            <div style={{ textAlign: "center", textAlign: "center", fontWeight: "500", color: "#000",  }}>
                              <p className="my-2">No Polls Created</p>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}