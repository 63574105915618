import {

  saveInquiryReg,

} from "../../Constant";

let defaultState = {

  saveInquiryReg: null,

};

const globalReducer = (state = defaultState, action) => {
  switch (action.type) {

    case saveInquiryReg:
      return Object.assign({}, state, {
        saveInquiryReg: action.payload,
      });        

    default:
      return state;
  }
};
export default globalReducer;
