import axios from "axios";
import {
  baseURL,
  IS_LOADING,
  saveInquiry_URL,
  saveInquiryReg,

} from "../../Constant";
import swal from "sweetalert";

function getAsios() {
  let token = localStorage.getItem("token");
  let authToken = localStorage.getItem("authToken");

  console.log("authToken", authToken)

  if (authToken) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: authToken,
      },
    });
  } else {
    return axios.create({
      baseURL: baseURL,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}

export function dispatchLoadingStatus(loading) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}





export function saveInquiryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(saveInquiry_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: saveInquiryReg, payload: response.data });
        } else {
          dispatch({ type: saveInquiryReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}



