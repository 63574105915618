import React, { Component } from "react";
// import './Loader.css';
import { useDispatch, useSelector } from 'react-redux';

function Loader() {

    const Loding = useSelector(state => state.LoadingStatusReducer.loading);
    return (
        Loding &&
        <div className="overlay-loader">
            <div className="overlay__inner">
                <div className="overlay__content">
                                 <div className="atbd-spin-dots spin-lg">
                     <span className="spin-dot badge-dot dot-primary"></span>
                     <span className="spin-dot badge-dot dot-primary"></span>
                     <span className="spin-dot badge-dot dot-primary"></span>
                     <span className="spin-dot badge-dot dot-primary"></span>
                 </div>
                </div>
            </div>
        </div>

//         <div className="loader" id="overlayer">
//             <span className="loader-overlay">
//                 <div className="atbd-spin-dots spin-lg">
//                     <span className="spin-dot badge-dot dot-primary"></span>
//                     <span className="spin-dot badge-dot dot-primary"></span>
//                     <span className="spin-dot badge-dot dot-primary"></span>
//                     <span className="spin-dot badge-dot dot-primary"></span>
//                 </div>
//             </span>
//             <div class="overlay-dark-sidebar"></div>
// <div class="customizer-overlay"></div>
//         </div>

    );
}


export default Loader;